/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/page-solutions/banner';
import BeardCrumbs from '../page-components/breadCrumbs';

// Assests
import BANNER_IMG from '../images/presentation/presentation.png';
import LUXOR_TECH_IMG from '../images/presentation/luxor-tech.png';
import TITLE_ICON from '../images/index/icons/title-icon.svg';

// Styles
import '../page-styles/presentation.scss';

/* -------------------------------------------------------------------------- */
/*                            Presentation Page                               */
/* -------------------------------------------------------------------------- */

function PresentationPage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Contact';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  //  localization
  const { t } = useTranslation();
  const {
    title,
    titleBC,
    path,
    subTitle,
    item1,
    item2,
    item3,
    item4,
    item5,
    link,
    sectionTitle,
    subSectionMissionsTitle,
    subSectionVisionsTitle,
    missions,
    visions,
  } = t('presentationpage', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */
  const Links = link?.map(({ id, item, url }) => (
    <Link key={id} to={url}>
      <li className="single-list">{item}</li>
    </Link>
  ));
  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <BannerSection bannerImage={BANNER_IMG}>
        <h1>{title}</h1>
      </BannerSection>
      <Visible lg xl xxl>
        <Container className="" fluid>
          <BeardCrumbs path={path} titleBreadcrumbs={titleBC} />
        </Container>
      </Visible>
      <Container className="presentation-page fluid" fluid>
        <Container className="presentation-page-content flex fluid" fluid>
          <Container className="fluid" fluid>
            <p className="description">
              <span className="title">{subTitle}</span>
              {item1}
              <br />
              {item2}
              <br />
              <br />
              {item3}
              <br />
              <br />
              {item4}
            </p>
          </Container>
          <img
            className="luxor-tech-img"
            src={LUXOR_TECH_IMG}
            alt="luxor-tech"
          />
        </Container>
        <p className="products-heading">{item5}</p>
        <ul>{Links}</ul>
        <br />
          {/* <Container className="missions-section fluid" fluid>
          <p className="section-content">
            <h3 className="section-title">{sectionTitle}</h3>
            <img src={TITLE_ICON} alt="title_icon" />
            {subSectionMissionsTitle}
            <br />
            {missions}
            <br />
            <br />

            <img src={TITLE_ICON} alt="title_icon" />
            {subSectionVisionsTitle}
            <br />
            {visions}
          </p>
        </Container> */}
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Presentation", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PresentationPage;
